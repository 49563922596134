import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Numeral from 'numeral';
import { Button, Copy, Link, Tabs, Tag } from '@hexure/ui';
import { Icon } from '@mdi/react';
import {
  mdiCheckboxBlankCircleOutline,
  mdiCheckboxMarkedCircle,
  mdiChevronRight,
  mdiChevronDown,
  mdiChevronUp,
  mdiCheckboxMarkedCircleOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked
} from '@mdi/js';

import Loader from 'shared/Loader';

import { ThemeContext } from 'shared/ThemeContext';

import { Colors } from 'constants/Clementine';

import ApiUtils from 'utils/AnnuitiesApi';
import ProductDetail from './ProductDetail';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Checkbox = styled.div`
  display: flex;
  padding: 16px 20px 0px 0px;
  align-items: flex-start;
  align-self: stretch;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  border-radius: 8px;
  border-width: ${props => (props.$isSelected ? '2px' : '1px')};
  border-style: solid;
  border-color: ${props => (props.$isSelected ? props.$color : '#E5E5E5')};
  background: #fff;
`;

const Top = styled.div`
  display: flex;
  flex: 1;
  padding: 16px;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  background: ${props => `${props.$color}0d`};
`;

const TopLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
`;

const Logo = styled.img`
  height: 30px;
  width: auto;
`;

const ProductName = styled.h5`
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
  padding: 0;
`;

const Bottom = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
`;

const BottomLeft = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: space-between;
`;

const Meta = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  flex-wrap: wrap;
  flex: 1;
`;

const TagLabel = styled.span`
  font-style: italic;
  margin-right: 4px;
`;

const TagValue = styled.span`
  font-weight: 600;
`;

const BottomRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CalculatedValue = styled.div`
  color: #000;
  text-align: right;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  align-self: stretch;
  border-bottom: 1px solid #e5e5e5;
  padding: 0px 12px 12px 0px;
`;

const Rates = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Rate = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 12px 0px 12px;
  align-items: flex-end;
  gap: 4px;
`;

const RateDivider = styled.div`
  width: 1px;
  background: #e5e5e5;
  align-self: stretch;
`;

const RateValue = styled.span`
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const RateLabel = styled.span`
  color: #999;
  font-family: Roboto;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
`;

const Details = styled.div`
  background: #fff;
  border-radius: 8px;
`;

const TabContent = styled.div`
  padding: 30px;
`;

const Features = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Feature = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Bold = styled.span`
  font-weight: 500;
`;

const ApproximateIncome = styled.span`
  color: #999;
  font-family: Roboto;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
`;

const QuoteCard = ({ hasActions, isSelected, onSelect, selectedQuotes, onShowActions, quote, carrier, product, solveFor, productType }) => {
  const { color } = useContext(ThemeContext);
  const [show_details, setShowDetails] = useState(false);
  const [active_tab, setActiveTab] = useState('features');
  const [loading, setLoading] = useState(false);
  const [ambest_record, setAmbestRecord] = useState(null);
  const [productDetail, setProductDetail] = useState(null);
  const meta = [
    {
      label: 'Rating',
      raw_value: quote.am_best_rating === 'System.String[]' ? '' : quote.am_best_rating || 'A+',
      value: quote.am_best_rating === 'System.String[]' ? '' : quote.am_best_rating || 'A+'
    },
    {
      label: 'Rate GTD',
      raw_value: quote.gtd_rate_years,
      value: `${quote.gtd_rate_years} Years`
    },
    {
      label: 'Surrender Charge',
      raw_value: quote.surrender_charge,
      value: `${quote.surrender_charge} Years`
    },
    ...(quote?.index_name
      ? [
          {
            label: 'Index',
            raw_value: quote?.index_name,
            value: quote?.index_name
          }
        ]
      : [])
  ];

  const tabs = [
    { isActive: active_tab === 'features', label: 'Features', onClick: setActiveTab.bind(null, 'features') },
    { isActive: active_tab === 'overview', label: 'Carrier Overview', onClick: setActiveTab.bind(null, 'overview') },
    { isActive: active_tab === 'details', label: 'Details', onClick: setActiveTab.bind(null, 'details') }
  ];

  if (product?.information) {
    tabs.push({ isActive: active_tab === 'information', label: 'Important Information', onClick: setActiveTab.bind(null, 'information') });
  }
  const calculated_value =
    solveFor === 'Accumulation'
      ? quote.accumulation
      : solveFor === 'Contribution'
        ? quote.premium
        : solveFor === 'Income'
          ? quote.monthly_income
          : solveFor === 'GMBIncome'
            ? quote.premium
            : quote.premium;

  useEffect(() => {
    if (active_tab === 'overview' && !ambest_record) {
      setLoading(true);

      ApiUtils.loadAmbestRecord(quote.carrier_id)
        .then(({ data }) => {
          setAmbestRecord(data[0]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (active_tab === 'details' && !productDetail) {
      setLoading(true);

      ApiUtils.loadProductDetail(quote.id)
        .then(response => {
          setProductDetail(response.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [active_tab]);
  const solveForMapping = {
    Accumulation: 'Approximated Accumulation',
    Contribution: 'Approximated Contribution',
    Income: 'Approximated Income',
    GMBIncome: 'Approximated Income'
  };
  const approximatedLabel = solveForMapping[solveFor] || 'Approximated Income';
  return (
    <Wrapper>
      <Checkbox onClick={onSelect.bind(null, quote.id)}>
        <Icon color={selectedQuotes?.includes(quote.id) ? color : '#999'} path={selectedQuotes?.includes(quote.id) ? mdiCheckboxMarked : mdiCheckboxBlankOutline} size='28px' />
      </Checkbox>
      <Content $color={color} $isSelected={isSelected}>
        <Top $color={color}>
          <TopLeft>
            <Logo src={carrier?.logo?.url} />
            <ProductName>{product?.product_name || quote?.product_name}</ProductName>
          </TopLeft>
          {/*<Button*/}
          {/*  icon={mdiChevronRight}*/}
          {/*  onClick={modal.bind(null, 'visualize', product)}*/}
          {/*  small={true}*/}
          {/*>*/}
          {/*  Visualize*/}
          {/*</Button>*/}
          {hasActions ? (
            <Button icon={mdiChevronRight} onClick={onShowActions.bind(null, quote)} small={true}>
              Get Started
            </Button>
          ) : null}
        </Top>
        <Bottom>
          <BottomLeft>
            <Meta>
              {meta.map((m, i) => {
                if (m.raw_value) {
                  return (
                    <Tag color='SUBTLE_GRAY' key={i}>
                      <TagLabel>{m.label}</TagLabel>
                      <TagValue>{m.value}</TagValue>
                    </Tag>
                  );
                }

                return null;
              })}
            </Meta>
            <Link onClick={setShowDetails.bind(null, !show_details)} style={{ display: 'flex', alignItems: 'center' }}>
              {show_details ? (
                <>
                  Hide Details <Icon color={color} path={mdiChevronUp} size='18px' />
                </>
              ) : (
                <>
                  Show Details <Icon color={color} path={mdiChevronDown} size='18px' />
                </>
              )}
            </Link>
          </BottomLeft>
          <BottomRight>
            <CalculatedValue>
              {Numeral(calculated_value).format('$0,0.00')}
              <br />
              <ApproximateIncome>{approximatedLabel}</ApproximateIncome>
            </CalculatedValue>
            <Rates>
              <Rate>
                <RateValue>{Numeral(productType === 'Fixed Annuity' ? quote.base_rate / 100 : quote.base_rate).format('0.00%')}</RateValue>
                <RateLabel>Guaranteed</RateLabel>
              </Rate>
              <RateDivider />
              <Rate>
                <RateValue>{Numeral(productType === 'Fixed Annuity' ? quote.current_rate / 100 : quote.current_rate).format('0.00%')}</RateValue>
                <RateLabel>Current</RateLabel>
              </Rate>
            </Rates>
          </BottomRight>
        </Bottom>
        {show_details ? (
          <Details>
            <Tabs style={{ borderTop: 'none' }} tabs={tabs} />
            <TabContent>
              {active_tab === 'features' ? (
                <Features>
                  {product?.features.map((f, i) => (
                    <Feature key={i}>
                      <Icon color={Colors.GREEN.hex} path={mdiCheckboxMarkedCircleOutline} size='20px' />
                      <Copy>{f.name || 'N/A'}</Copy>
                    </Feature>
                  ))}
                </Features>
              ) : null}

              {active_tab === 'overview' ? (
                <>
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <Copy>
                        <Bold>A.M. Best Rating:</Bold> {ambest_record?.financial_strength_rating_current || 'N/A'}
                      </Copy>
                      <Copy>
                        <Bold>Financial Size Category:</Bold> {ambest_record?.financial_strength_category || 'N/A'}
                      </Copy>
                      <Copy>
                        <Bold>Address:</Bold> {ambest_record ? `${ambest_record.street_address}, ${ambest_record.city}, ${ambest_record.state}, ${ambest_record.zip_code}` : 'N/A'}
                      </Copy>
                      <Copy>
                        <Bold>Phone:</Bold> {ambest_record?.phone_number || 'N/A'}
                      </Copy>
                      {ambest_record ? (
                        <Copy margin='20px 0px 0px'>
                          Rating as of {dayjs(ambest_record.financial_strength_rating_effective_date).format('M/D/YYYY')}. For the latest rating, access{' '}
                          <Link href='https://www.ambest.com'>www.ambest.com</Link>. A description of A.M. Best Ratings can be found on the A.M. Best website at{' '}
                          <Link href='https://www.ambest.com/ratings/guide.asp'>https://www.ambest.com/ratings/guide.asp</Link>
                        </Copy>
                      ) : null}
                    </>
                  )}
                </>
              ) : null}

              {active_tab === 'details' ? <ProductDetail carrier={carrier} data={productDetail} productType={productType} /> : null}

              {active_tab === 'information' ? <Copy>{product.information}</Copy> : null}
            </TabContent>
          </Details>
        ) : null}
      </Content>
    </Wrapper>
  );
};

QuoteCard.propTypes = {
  carrier: PropTypes.object,
  hasActions: PropTypes.bool,
  isSelected: PropTypes.bool,
  modal: PropTypes.func,
  onSelect: PropTypes.func,
  onShowActions: PropTypes.func,
  product: PropTypes.object,
  quote: PropTypes.object,
  selectedQuotes: PropTypes.array,
  solveFor: PropTypes.string
};

export default QuoteCard;
