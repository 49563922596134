import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@mdi/react';

import { ThemeContext } from 'shared/ThemeContext';

const Card = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${props => (props.$isSelected ? '#fff' : '#F9F9F9')};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.$isSelected ? props.$color : '#fff')};
  cursor: pointer;

  &:hover {
    background: ${props => `${props.$color}0d`};

    > svg {
      color: ${props => props.$color};
    }
  }
`;

const Label = styled.span`
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const StyledIcon = styled(Icon)`
  /* color: #999999; */
`;

const ActionCard = ({ children, id = 'action-card', icon, isSelected, onClick }) => {
  const { color } = useContext(ThemeContext);

  return (
    <Card id={id} $color={color} $isSelected={isSelected} onClick={onClick}>
      {icon ? <StyledIcon path={icon} size='40px' /> : null}
      {children ? <Label>{children}</Label> : null}
    </Card>
  );
};

ActionCard.propTypes = {
  icon: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
};

export default ActionCard;
