import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Copy, DatePicker, Heading, Field, Input, Link, MultiSelect, Select, Button, Modal, Tabs, Checkbox } from '@hexure/ui';
import { mdiCheckboxMarkedCircleOutline, mdiChevronRight, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

import ApiUtils from 'utils/AnnuitiesApi';
import FormUtils from 'utils/Form';
// import Checkbox from 'shared/Checkbox';

const CriteriaTabs = styled.div`
  margin-top: -1px;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
`;

const CriteriaContent = styled.div`
  display: flex;
  min-height: 400px;
  flex-direction: ${props => (props.$isMobile ? 'column' : 'row')};
  min-height: ${props => (props.$isMobile ? 'auto' : '400px')};
  width: 100%;
  overflow-y: scroll;
`;

const CriteriaForm = styled.div`
  display: flex;
  width: ${props => (props.$isMobile ? 'auto' : '480px')};
  max-height: calc(100vh - 310px);
  overflow-y: ${props => (props.$isMobile ? 'visible' : 'auto')};
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

const CriteriaHelp = styled.div`
  display: flex;
  width: ${props => (props.$isMobile ? 'auto' : '320px')};
  padding: 50px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  align-self: stretch;
  background: #fcfcfc;
`;

const HelpContent = styled.div`
  color: #000;
  font-family: Roboto Slab;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const StyledSelect = styled.select`
  border-color: #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 0 10px;
  width: 100%;

  &:focus-within {
    border-color: #0193d7;
  }
`;

const CriteriaModal = ({
  agent,
  agentErrors,
  authenticated,
  carriers,
  client,
  clientErrors,
  criteriaOptions,
  onApply,
  onClose,
  product,
  setTrackProduct,
  productErrors,
  productsFeatures,
  setTrackAgent,
  setAuth,
  setTrackClient,
  solve,
  setTrackSolve,
  solveErrors,
  tab = 'agent',
  validateCriteria
}) => {
  const [activeTab, setActiveTab] = useState(tab);
  const [showLogin, toggleShowLogin] = useState(false);
  const [tempErrors, setTempErrors] = useState([
    ...agentErrors.map(e => `agent_${e}`),
    ...clientErrors.map(e => `client_${e}`),
    ...productErrors.map(e => `product_${e}`),
    ...solveErrors.map(e => `solve_${e}`)
  ]);
  const [loginErrors, setLoginErrors] = useState([]);
  const [loggingIn, setLoggingIn] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [loginCheck, setLoginCheck] = useState(false);
  const [ageOption, setAgeOption] = useState(client?.current_age ? 'age' : 'dob');
  const [agentData, setAgentData] = useState(
    agent || {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      insurance_license_number: '',
      sign_up_check: false,
      password: '',
      confirm_password: ''
    }
  );
  const [clientData, setClientData] = useState(
    client || {
      first_name: '',
      last_name: '',
      date_of_birth: dayjs().subtract(50, 'years').format('YYYY-MM-DD'),
      gender: 'Male',
      state: criteriaOptions.default_state
    }
  );
  const [productData, setProductData] = useState(
    product || {
      product_types: [criteriaOptions.product_types[0].value],
      carrier_ids: '',
      am_best_rating: ['Any'],
      surrender_charge_period: '',
      initial_rate_term: ['0'],
      min_initial_rate: '0.0',
      bonus_rate: false,
      tax_qualification: 'Non-Qualified',
      features: [],
      gmb_rider: true,
      index_id: ''
    }
  );
  const [marketIndicies, setMarketIndicies] = useState([]);
  const [rate_term, setRateTerm] = useState(null);
  const [solveData, setSolveData] = useState(
    solve || {
      solve_for: 'Income',
      premium: 0,
      start_year_offset: 0,
      gmb_start_year: 0,
      quote_value: '1',
      enforce_rmds: false
    }
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const tabs = [
    {
      isActive: activeTab === 'agent',
      label: 'Agent',
      onClick: setActiveTab.bind(null, 'agent'),
      badgeCount: tempErrors.filter(e => e.includes('agent_')).length,
      errorBadge: true
    },
    {
      isActive: activeTab === 'client',
      label: 'Client',
      onClick: setActiveTab.bind(null, 'client'),
      badgeCount: tempErrors.filter(e => e.includes('client_')).length,
      errorBadge: true
    },
    {
      isActive: activeTab === 'product',
      label: 'Product',
      onClick: setActiveTab.bind(null, 'product'),
      badgeCount: tempErrors.filter(e => e.includes('product_')).length,
      errorBadge: true
    },
    {
      isActive: activeTab === 'solve',
      label: 'Solve',
      onClick: setActiveTab.bind(null, 'solve'),
      badgeCount: tempErrors.filter(e => e.includes('solve_')).length,
      errorBadge: true
    }
  ];
  const getSolveDescription = solve_for => {
    switch (solve_for) {
      case 'Income':
        return 'If the client wants to contribute a specific amount, select "(GMB) Income based on Contribution" and provide that contribution amount.';
      case 'Accumulation':
        return 'If the client wants to contribute a specific amount, select "Accumulation Value by Contribution" and provide that contribution amount.';
      case 'Contribution':
        return 'If the client desires a specific accumulation value, select "Contribution by Accumulation" Value and provide that accumulation value.';
      case 'GMBIncome':
        return 'If the client wants to contribute a specific amount, select "Contribution based on (GMB) Income" and provide that Income amount.';
      default:
        return 'If the desired income amount is known, select Contribution (or Premium) and provide that amount. If the client has a specific amount that they want to spend, select Income and input the contribution amount.';
    }
  };
  const tab_help_content = {
    agent: {
      title: 'Who is the Agent?',
      description:
        'An agent is the individual who works with a client to determine what needs they have and what products can fulfill those needs. This person can also be referred to as the Producer.'
    },
    client: {
      title: 'Who is the Client?',
      description: 'The client is the person who is opening the Annuity account. This person can as be referred to as the Customer, Owner or Annuitant.'
    },
    product: {
      title: 'Selecting an Annuity Product',
      description: 'When selecting a product, here are some things to think about: Timing, duration and type of income desired; Client risk profile and tolerance.'
    },
    solve: {
      title: 'Income vs. Contribution',
      description: getSolveDescription(solveData.solve_for)
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const getIconStyle = condition => ({
    color: condition ? '#4CAF50' : '#F44336' // Green 500 if true, Red 500 if false
  });
  const criteria = [
    { condition: agentData.password?.match(/\d{2,}/), text: 'At least 2 numbers' },
    { condition: agentData.password?.match(/[A-Z]/), text: 'At least 1 uppercase letter' },
    { condition: agentData.password?.match(/[a-z]/), text: 'At least 1 lowercase letter' },
    { condition: agentData.password?.match(/[^a-zA-Z0-9]/), text: 'At least 1 special character' },
    { condition: agentData.password?.length >= 8, text: 'At least 8 total characters' }
  ];
  const criterionStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  };
  const PasswordCriterion = ({ condition, text }) => (
    <div style={criterionStyle}>
      <Icon path={condition ? mdiCheckboxMarkedCircleOutline : mdiClose} size={1} style={getIconStyle(condition)} />
      <span>{text}</span>
    </div>
  );

  const handleAgentChange = (name, e) => {
    const updated_agent = { ...agentData, [name]: name === 'sign_up_check' ? e.target.checked : e.target.value };
    const error_name = `agent_${name}`;
    const new_errors = tempErrors.filter(error => error !== error_name);

    if (!validateCriteria('agent', name, e.target.value)) {
      new_errors.push(error_name);
    }

    setTempErrors(new_errors);
    setAgentData(updated_agent);
    setTrackAgent(updated_agent);
  };

  const handleClientChange = (name, e) => {
    const updated_client = clientData;
    const error_name = `client_${name}`;
    const new_errors = [...tempErrors.filter(e => e !== error_name)];

    if (!validateCriteria('client', name, e.target.value)) {
      new_errors.push(error_name);
    }

    if (name === 'current_age') {
      clientData.nearest_age = e.target.value;
    }

    setTempErrors(new_errors);
    setClientData({
      ...updated_client,
      [name]: e.target.value
    });
    setTrackClient({
      ...updated_client,
      [name]: e.target.value
    });
  };

  const handleProductChange = (name, e) => {
    let updatedSolveData = { ...solveData };
    const error_name = `product_${name}`;
    const new_errors = [...tempErrors.filter(e => e !== error_name)];

    if (!validateCriteria('product', name, e.target.value)) {
      new_errors.push(error_name);
    }

    setTempErrors(new_errors);
    if (name === 'bonus_rate' || name === 'gmb_rider') {
      setProductData({
        ...productData,
        [name]: e.target.checked
      });
    } else if (name === 'initial_rate_term') {
      setProductData({
        ...productData,
        [name]: [e.target.value]
      });
    } else {
      setProductData({
        ...productData,
        [name]: name === 'product_types' ? [e.target.value] : e.target.value
      });
      setTrackProduct({
        ...productData,
        [name]: name === 'product_types' ? [e.target.value] : e.target.value
      });
      if (['Multi-Year Guaranteed Annuity', 'Fixed Annuity', 'Fixed Indexed Annuity', 'Single Premium Immediate Annuity', 'Deferred Income Annuity'].includes(e.target.value)) {
        updatedSolveData = {
          ...updatedSolveData,
          start_year_offset: 0,
          gmb_start_year: 0
        };
      }
    }
    setSolveData(updatedSolveData);
    setTrackSolve(updatedSolveData);
  };

  const handleFeatureChange = (name, e) => {
    const feature_id = e.target.value;
    const features = productData?.features?.filter(f => f !== feature_id) || [];

    if (!productData?.features?.includes(feature_id)) {
      features?.push(feature_id);
    }

    setProductData({
      ...productData,
      [name]: e.target.value
    });
    setTrackProduct({
      ...productData,
      [name]: e.target.value
    });
  };

  const handleMarketIndexChange = (name, e) => {
    setProductData({
      ...productData,
      [name]: e.target.value
    });
  };

  const handleSolveChange = (name, e) => {
    const error_name = `solve_${name}`;
    const new_errors = [...tempErrors.filter(e => e !== error_name)];

    if (!validateCriteria('solve', name, e.target.value)) {
      new_errors.push(error_name);
    }

    setTempErrors(new_errors);
    let updatedSolveData = { ...solveData };

    if (name === 'gmb_start_year') {
      updatedSolveData = {
        ...updatedSolveData,
        start_year_offset: 0
      };
    }
    if (name === 'start_year_offset') {
      updatedSolveData = {
        ...updatedSolveData,
        gmb_start_year: 0
      };
    }

    updatedSolveData[name] = e.target.value;
    if (name === 'enforce_rmds') {
      updatedSolveData[name] = e.target.checked;
    }

    setSolveData(updatedSolveData);
    setTrackSolve(updatedSolveData);
  };

  const toggleLogin = () => {
    toggleShowLogin(!showLogin);
  };

  const toggleAgeOption = age_option => {
    const newClientData = { ...clientData };

    if (age_option === 'dob') {
      delete newClientData.current_age;
      delete newClientData.nearest_age;

      const currentDate = dayjs(clientData.date_of_birth);
      const newYear = dayjs().year() - clientData.current_age;
      const newBirthDate = currentDate.year(newYear);

      newClientData.date_of_birth = newBirthDate;
    } else {
      const birthDate = dayjs(clientData.date_of_birth);
      const currentDate = dayjs();
      const age = currentDate.diff(birthDate, 'year');

      newClientData.current_age = age;
      newClientData.nearest_age = age + 1;
    }

    setClientData(newClientData);
    setAgeOption(age_option);
  };

  const handleLogin = () => {
    const errors = [];

    if (!FormUtils._validateEmail(agentData.email)) {
      errors.push('email');
    }

    if (!agentData.password) {
      errors.push('password');
    }

    if (errors.length) {
      setLoginErrors(errors);
    } else {
      setLoggingIn(true);

      ApiUtils.login({ email: agentData.email, password: agentData.password })
        .then(response => {
          const auth = {
            access_token: response.headers['access-token'],
            client: response.headers['client'],
            uid: response.headers['uid'],
            id: response.data.id
          };

          Cookies.set('ixn', auth, { domain: window.location.hostname });
          setLoginErrors([]);
          setLoginCheck(true);
          setLoggingIn(false);
          setAuth(auth);
        })
        .catch(() => {
          setLoginErrors(['email', 'password']);
          setLoggingIn(false);
          setAuth(null);
        });
    }
  };

  const handleLogout = () => {
    ApiUtils.logout().then(() => {
      Cookies.remove('ixn', { domain: window.location.hostname });
      setAuth(null);
      setLoginCheck(false);
    });
  };

  const toggleHeight = () => {
    setIsExpanded(!isExpanded);
  };

  const applyCriteria = () => {
    if (activeTab === 'solve') onApply(agentData, clientData, productData, solveData);

    switch (activeTab) {
      case 'agent':
        setActiveTab('client');
        break;
      case 'client':
        setActiveTab('product');
        break;
      case 'product':
        setActiveTab('solve');
        break;
      default:
        onClose();
    }
  };

  const applyChanges = () => {
    onApply(agentData, clientData, productData, solveData);
    onClose();
  };

  // const isValidNumberFormat = input => {
  //   const regex = /^[0-9]+(\.[0-9]+)?$/;
  //   return !regex.test(input);
  // };

  const handleBack = () => {
    switch (activeTab) {
      case 'client':
        setActiveTab('agent');
        break;
      case 'product':
        setActiveTab('client');
        break;
      case 'solve':
        setActiveTab('product');
        break;
      case 'agent':
        if (!showLogin && !agentData.sign_up_check) {
          onClose();
        } else if (showLogin) {
          toggleShowLogin(false);
        } else if (agentData.sign_up_check) {
          setAgentData({ ...agentData, sign_up_check: false });
        } else {
          onClose();
        }
        break;
      default:
        onClose();
    }
  };
  const getOptions = () => {
    const baseOptions = [
      { value: 'Accumulation', label: 'Accumulation, based on contribution' },
      { value: 'Contribution', label: 'Contribution, based on Account Value' }
    ];

    const gmbOptions = [
      { value: 'Income', label: '(GMB) Income based on Contribution' },
      { value: 'GMBIncome', label: 'Contribution based on (GMB) Income' }
    ];

    const combinedOptions = productData.product_types[0] === 'Fixed Annuity' || productData.product_types[0] === 'Multi-Year Guaranteed Annuity' ? [...baseOptions] : [...gmbOptions, ...baseOptions];

    return combinedOptions;
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    ApiUtils.loadMarketIndicies().then(response => {
      const options = response.data.map(index => ({ value: index.id, label: index.name }));
      setMarketIndicies(options);
    });

    ApiUtils.loadRateTerms().then(response => {
      setRateTerm(response.data);
    });
  }, []);

  useEffect(() => {
    setLoggingIn(false);

    if (showLogin && authenticated) {
      toggleLogin(false);
    }
  }, [authenticated, loginErrors]);

  useEffect(() => {
    setAgentData({
      first_name: agent?.first_name || agentData?.first_name || '',
      last_name: agent?.last_name || agentData?.last_name || '',
      email: agent?.email || agentData?.email || '',
      phone: agent?.phone || agentData?.phone || '',
      insurance_license_number: agent?.insurance_license_number || agentData?.insurance_license_number || ''
    });
  }, [agent]);

  return (
    <Modal
      onClose={onClose}
      primaryButton={{
        children: activeTab === 'solve' ? 'Save & Apply Changes' : 'Next',
        icon: activeTab === 'solve' ? mdiCheckboxMarkedCircleOutline : null,
        onClick: applyCriteria
      }}
      {...(activeTab !== 'solve'
        ? {
            secondaryButton: {
              children: 'Save & Apply Changes',
              onClick: applyChanges
            }
          }
        : {})}
      tertiaryButton={{
        children: 'Back',
        onClick: handleBack
      }}
      {...(showLogin
        ? {
            quarternaryButton: {
              children: 'Agent Details',
              onClick: toggleLogin
            }
          }
        : {})}
      title='Search Criteria'
    >
      <CriteriaTabs>
        <Tabs tabs={tabs} />
      </CriteriaTabs>
      <CriteriaContent $isMobile={windowWidth < 900}>
        <CriteriaForm $isMobile={windowWidth < 900}>
          {activeTab === 'agent' ? (
            <>
              {showLogin ? (
                <>
                  <Field label='Email Address' required={true} style={{ width: '100%' }}>
                    <Input
                      id='login-email-address'
                      invalid={loginErrors.includes('email')}
                      onChange={handleAgentChange.bind(null, 'email')}
                      readOnly={loggingIn}
                      required={true}
                      value={agentData.email}
                    />
                  </Field>
                  <Field label='Password' required={true} style={{ width: '100%' }}>
                    <Input
                      id='login-password'
                      invalid={loginErrors.includes('password')}
                      onChange={handleAgentChange.bind(null, 'password')}
                      readOnly={loggingIn}
                      required={true}
                      type='password'
                      value={agentData.password}
                    />
                  </Field>
                  <Button id='login-button' disabled={loggingIn} icon={mdiChevronRight} onClick={handleLogin} small={true}>
                    {loggingIn ? 'Authenticating...' : 'Log In'}
                  </Button>
                  <Copy margin='18px 0px 0px 0px'>
                    Don't have an account?{' '}
                    <Link id='login-toggle' onClick={toggleLogin}>
                      Enter info manually
                    </Link>
                  </Copy>
                </>
              ) : agentData?.sign_up_check ? (
                <>
                  <Field label='Password' required={true} style={{ width: '100%' }}>
                    <Input
                      id='signup-password'
                      invalid={loginErrors.includes('password')}
                      onChange={handleAgentChange.bind(null, 'password')}
                      readOnly={loggingIn}
                      required={true}
                      type='password'
                      value={agentData.password}
                    />
                  </Field>
                  <Field label='Confirm Password' required={true} style={{ width: '100%' }}>
                    <Input
                      id='signup-confirm-password'
                      invalid={loginErrors.includes('password')}
                      onChange={handleAgentChange.bind(null, 'confirm_password')}
                      readOnly={loggingIn}
                      required={true}
                      type='password'
                      value={agentData.confirm_password}
                    />
                  </Field>
                  <div style={{ marginTop: '10px' }}>
                    {criteria.map((criterion, index) => (
                      <PasswordCriterion key={index} condition={criterion.condition} text={criterion.text} />
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <Row>
                    <Field label='First Name' required={true} style={{ width: 150 }}>
                      <Input
                        id='agent-first-name'
                        invalid={tempErrors.includes('agent_first_name')}
                        onChange={handleAgentChange.bind(null, 'first_name')}
                        required={true}
                        value={agentData.first_name}
                      />
                    </Field>
                    <Field label='Last Name' required={true} style={{ flex: 1 }}>
                      <Input id='agent-last-name' invalid={tempErrors.includes('agent_last_name')} onChange={handleAgentChange.bind(null, 'last_name')} required={true} value={agentData.last_name} />
                    </Field>
                  </Row>
                  <Field label='Email Address' required={true} style={{ width: '100%' }}>
                    <Input id='agent-email' invalid={tempErrors.includes('agent_email')} onChange={handleAgentChange.bind(null, 'email')} required={true} value={agentData.email} />
                  </Field>
                  <Field label='Phone Number' required={true} style={{ width: '100%' }}>
                    <Input id='agent-phone' format='phone' invalid={tempErrors.includes('agent_phone')} onChange={handleAgentChange.bind(null, 'phone')} required={true} value={agentData.phone} />
                  </Field>
                  <Field label='License Number' required={true} style={{ width: '100%' }}>
                    <Input
                      id='agent-license-number'
                      invalid={tempErrors.includes('agent_insurance_license_number')}
                      onChange={handleAgentChange.bind(null, 'insurance_license_number')}
                      required={true}
                      value={agentData.insurance_license_number}
                    />
                  </Field>
                  {!loginCheck ? (
                    <Row>
                      <Checkbox children='Save and create an account' onChange={handleAgentChange.bind(null, 'sign_up_check')} style={{ marginBottom: 20 }} checked={productData.sign_up_check} />
                    </Row>
                  ) : null}
                  {authenticated ? (
                    <Copy>
                      Information pre-filled from your account. <Link onClick={handleLogout}>Logout</Link>
                    </Copy>
                  ) : (
                    <Copy>
                      Have an account? <Link onClick={toggleLogin}>Login</Link>
                    </Copy>
                  )}
                </>
              )}
            </>
          ) : null}

          {activeTab === 'client' ? (
            <>
              <Row>
                <Field label='First Name' required={true} style={{ width: 150 }}>
                  <Input invalid={tempErrors.includes('client_first_name')} onChange={handleClientChange.bind(null, 'first_name')} required={true} value={clientData.first_name} />
                </Field>
                <Field label='Last Name' required={true} style={{ flex: 1 }}>
                  <Input invalid={tempErrors.includes('client_last_name')} onChange={handleClientChange.bind(null, 'last_name')} required={true} value={clientData.last_name} />
                </Field>
              </Row>
              {ageOption === 'dob' ? (
                <Field
                  action={{
                    label: 'Enter Age',
                    onClick: toggleAgeOption.bind(null, 'age')
                  }}
                  label='Date of Birth'
                  required={true}
                  style={{ width: '100%' }}
                >
                  <DatePicker date={clientData.date_of_birth} maxDate={dayjs()} onChange={handleClientChange.bind(null, 'date_of_birth')} />
                </Field>
              ) : (
                <Field
                  action={{
                    label: 'Enter Date of Birth',
                    onClick: toggleAgeOption.bind(null, 'dob')
                  }}
                  label='Current/Nearest Age'
                  required={true}
                  style={{ width: '100%' }}
                >
                  <Row>
                    <Input
                      invalid={tempErrors.includes('client_current_age')}
                      max={110}
                      min={1}
                      onChange={handleClientChange.bind(null, 'current_age')}
                      required={true}
                      style={{ width: '100%' }}
                      type='number'
                      value={clientData.current_age}
                    />
                    <Select
                      flexGrow={1}
                      onChange={handleClientChange.bind(null, 'current_age')}
                      options={[{ value: clientData.current_age }, { value: `${parseInt(clientData.current_age, 10) + 1}` }]}
                      style={{ width: '100%' }}
                      value={clientData.current_age}
                    />
                  </Row>
                </Field>
              )}

              <Row>
                <Field label='Gender' required={true} style={{ flex: 1 }}>
                  <Select onChange={handleClientChange.bind(null, 'gender')} options={[{ value: 'Male' }, { value: 'Female' }]} required={true} value={clientData.gender} />
                </Field>
                <Field label='State' required={true} style={{ flex: 1 }}>
                  <Select onChange={handleClientChange.bind(null, 'state')} options={criteriaOptions.states} required={true} value={clientData.state} />
                </Field>
              </Row>
            </>
          ) : null}

          {activeTab === 'product' ? (
            <>
              <Field label='Product Type' required={true} style={{ width: '100%' }}>
                <StyledSelect onChange={handleProductChange.bind(null, 'product_types')} value={productData.product_types[0]}>
                  {criteriaOptions.product_types.map((item, index) => (
                    <option
                      disabled={!['Fixed Annuity', 'Fixed Indexed Annuity', 'Multi-Year Guaranteed Annuity'].includes(item.value)}
                      key={`${item.value}-${index}`}
                      required={true}
                      style={!['Fixed Annuity', 'Fixed Indexed Annuity', 'Multi-Year Guaranteed Annuity'].includes(item.value) ? { color: 'gray' } : {}}
                      value={item.value}
                    >
                      {item.value}
                    </option>
                  ))}
                </StyledSelect>
              </Field>
              <Row>
                <Field label='Carriers' required={true} style={{ width: '320px' }}>
                  <MultiSelect
                    displayCount={1}
                    invalid={tempErrors.includes('product_carrier_ids')}
                    onChange={handleProductChange.bind(null, 'carrier_ids')}
                    options={carriers.map(c => ({
                      value: c.id,
                      label: c.name
                    }))}
                    readOnly={!carriers.length}
                    required={true}
                    selected={productData.carrier_ids}
                    showSelectAll={true}
                  />
                </Field>
                <Field label='A.M. Best Rating' style={{ flex: 1 }}>
                  <MultiSelect
                    onChange={handleProductChange.bind(null, 'am_best_rating')}
                    options={[
                      { value: 'Any' },
                      { value: 'A++' },
                      { value: 'A+' },
                      { value: 'A' },
                      { value: 'A-' },
                      { value: 'B++' },
                      { value: 'B+' },
                      { value: 'B' },
                      { value: 'B-' },
                      { value: 'C++' },
                      { value: 'C+' },
                      { value: 'C' },
                      { value: 'C-' },
                      { value: 'D' }
                    ]}
                    required={true}
                    selected={productData.am_best_rating}
                    showSelectAll={true}
                  />
                </Field>
              </Row>
              {['Fixed Annuity', 'Fixed Indexed Annuity', 'Multi-Year Guaranteed Annuity'].includes(productData.product_types[0]) ? (
                <Field label='Surrender Charge Period' required={true} style={{ width: '100%' }}>
                  <Select
                    onChange={handleProductChange.bind(null, 'surrender_charge_period')}
                    options={[
                      { value: '', label: 'Any' },
                      { value: 2, label: '2 year' },
                      { value: 3, label: '3 year' },
                      { value: 5, label: '5 year' },
                      { value: 6, label: '6 year' },
                      { value: 7, label: '7 year' },
                      { value: 9, label: '9 year' },
                      { value: 10, label: '10 year' }
                    ]}
                    required={true}
                    value={productData.surrender_charge_period}
                  />
                </Field>
              ) : null}

              {['Fixed Annuity', 'Multi-Year Guaranteed Annuity'].includes(productData.product_types[0]) ? (
                <>
                  <Row>
                    <Field label='Initial Rate Term' style={{ flex: 1 }}>
                      <Select
                        onChange={handleProductChange.bind(null, 'initial_rate_term')}
                        options={[
                          { label: 'Any', value: '0' },
                          ...(productData?.product_types[0] === 'Fixed Annuity'
                            ? (rate_term?.available_fa_initial_rate_terms || []).map(rate => ({ label: rate === 1 ? `${rate} Year` : `${rate} Years`, value: rate })).sort((a, b) => a.value - b.value)
                            : (rate_term?.available_myga_initial_rate_terms || [])
                                .map(rate => ({ label: rate === 1 ? `${rate} Year` : `${rate} Years`, value: rate }))
                                .sort((a, b) => a.value - b.value))
                        ]}
                        required={true}
                        value={productData?.initial_rate_term[0] || '0'}
                      />
                    </Field>
                    <Field label='Min. Initial Rate %' style={{ flex: 1 }}>
                      <Input onChange={handleProductChange.bind(null, 'min_initial_rate')} value={productData.min_initial_rate} placeholder='0.0' />
                    </Field>
                  </Row>
                  <Checkbox
                    children='Include initial year bonus rate if applicable'
                    onChange={handleProductChange.bind(null, 'bonus_rate')}
                    style={{ marginBottom: 20 }}
                    checked={productData.bonus_rate}
                  />
                </>
              ) : null}

              {['Multi-Year Guaranteed Annuity'].includes(productData.product_types[0]) ? (
                <>
                  <Field label='Guaranteed Period' required={true} style={{ width: '100%' }}>
                    <Select
                      onChange={handleProductChange.bind(null, 'guaranteed_period')}
                      options={[
                        { value: '2 year' },
                        { value: '3 year' },
                        { value: '4 year' },
                        { value: '5 year' },
                        { value: '6 year' },
                        { value: '7 year' },
                        { value: '8 year' },
                        { value: '9 year' },
                        { value: '10 year' }
                      ]}
                      required={true}
                      value={productData.guaranteed_period}
                    />
                  </Field>
                  {false && (
                    <Field label='Market Value Adjustment' required={true} style={{ width: '100%' }}>
                      <Select
                        onChange={handleProductChange.bind(null, 'market_value_adjustment')}
                        options={[{ value: 'Any' }, { value: 'Yes' }, { value: 'No' }]}
                        required={true}
                        value={productData.market_value_adjustment}
                      />
                    </Field>
                  )}
                </>
              ) : null}

              {['Single Premium Immediate Annuity', 'Deferred Income Annuity'].includes(productData.product_types[0]) ? (
                <>
                  <Field label='Income Start Date' required={true} style={{ width: '100%' }}>
                    <Input
                      min={dayjs()
                        .add(1, productData.product_types[0] === 'Deferred Income Annuity' ? 'year' : 'month')
                        .format('YYYY-MM-DD')}
                      onChange={handleProductChange.bind(null, 'income_start_date')}
                      required={true}
                      type='date'
                      value={productData.income_start_date}
                    />
                  </Field>
                  <Row>
                    <Field label='Income Payment Mode' required={true} style={{ width: '100%' }}>
                      <Select
                        onChange={handleProductChange.bind(null, 'income_payment_mode')}
                        options={[{ value: 'Annually' }, { value: 'Monthly' }]}
                        required={true}
                        value={productData.income_payment_mode}
                      />
                    </Field>
                    <Field label='Payment Option' required={true} style={{ width: '100%' }}>
                      <Select
                        onChange={handleProductChange.bind(null, 'payment_option')}
                        options={[
                          { value: 'Life Only' },
                          { value: 'Period Certain Only' },
                          { value: 'Life with Period Certain' },
                          { value: 'Life with Cash Refund' },
                          { value: 'Life with Installment Refund' }
                        ]}
                        required={true}
                        value={productData.payment_option}
                      />
                    </Field>
                  </Row>
                </>
              ) : null}
              <div style={{ width: '100%', height: isExpanded ? '300px' : 'auto' }}>
                <Field label='Features' onClick={toggleHeight} style={{ width: '100%' }}>
                  <MultiSelect
                    displayCount={1}
                    onChange={handleFeatureChange.bind(null, 'features')}
                    options={productsFeatures.map(c => ({
                      value: c.id,
                      label: c.name
                    }))}
                    readOnly={!productsFeatures.length}
                    required={true}
                    selected={productData.features}
                    showSelectAll={true}
                  />
                </Field>
                {false && ( // This will hide this from the user for now. QP-3844
                  <Row>
                    <Field label='Indexes' style={{ flex: 1 }}>
                      <Select onChange={handleMarketIndexChange.bind(null, 'index_id')} options={marketIndicies} required={true} />
                    </Field>
                  </Row>
                )}
                <Field label='Tax Qualification' required={true} style={{ flex: 1 }}>
                  <Select
                    onChange={handleProductChange.bind(null, 'tax_qualification')}
                    options={[{ value: 'Non-Qualified' }, { value: 'Qualified' }]}
                    required={true}
                    value={productData.tax_qualification}
                  />
                </Field>
                {['Fixed Indexed Annuity'].includes(productData.product_types[0]) ? (
                  <>
                    <Row>
                      <Checkbox children='Elect GMB Rider' onChange={handleProductChange.bind(null, 'gmb_rider')} style={{ marginBottom: 20 }} checked={productData.gmb_rider} />
                    </Row>
                  </>
                ) : null}
              </div>
            </>
          ) : null}

          {activeTab === 'solve' ? (
            <>
              <Row>
                <Field label='Solve for...' required={true} style={{ flex: 1 }}>
                  <Select onChange={handleSolveChange.bind(null, 'solve_for')} options={getOptions()} required={true} value={solveData.solve_for} />
                </Field>
                {solveData.solve_for === 'Income' ? (
                  <Field label='Contribution' required={true} style={{ width: 150 }}>
                    <Input format='currency' invalid={tempErrors.includes('solve_premium')} onChange={handleSolveChange.bind(null, 'premium')} required={true} value={solveData.premium} />
                  </Field>
                ) : solveData.solve_for === 'GMBIncome' ? (
                  <Field label='Income' required={true} style={{ width: 150 }}>
                    <Input format='currency' invalid={tempErrors.includes('solve_premium')} onChange={handleSolveChange.bind(null, 'premium')} required={true} value={solveData.premium} />
                  </Field>
                ) : solveData.solve_for === 'Accumulation' ? (
                  <Field label='Contribution' required={true} style={{ width: 150 }}>
                    <Input format='currency' invalid={tempErrors.includes('solve_premium')} onChange={handleSolveChange.bind(null, 'premium')} required={true} value={solveData.premium} />
                  </Field>
                ) : solveData.solve_for === 'Contribution' ? (
                  <Field label='Account Value' required={true} style={{ width: 150 }}>
                    <Input format='currency' invalid={tempErrors.includes('solve_premium')} onChange={handleSolveChange.bind(null, 'premium')} required={true} value={solveData.premium} />
                  </Field>
                ) : null}
              </Row>
              {false && (
                <Row>
                  {['Single Premium Immediate Annuity', 'Deferred Income Annuity'].includes(productData.product_types[0]) ? (
                    <Field label='Start Year Offset' required={true} style={{ width: 150 }}>
                      <Input
                        invalid={tempErrors.includes('solve_start_year_offset')}
                        onChange={handleSolveChange.bind(null, 'start_year_offset')}
                        required={true}
                        type='number'
                        value={solveData.start_year_offset}
                      />
                    </Field>
                  ) : null}
                  {false && ['Fixed Annuity', 'Fixed Indexed Annuity'].includes(productData.product_types[0]) && ['Income', 'Premium'].includes(solveData.solve_for) ? (
                    <Field label='GMB Activation Year' required={true} style={{ flex: 1 }}>
                      <Select
                        invalid={tempErrors.includes('solve_gmb_start_year')}
                        onChange={handleSolveChange.bind(null, 'gmb_start_year')}
                        options={[
                          { value: '0', label: 'No Activation' },
                          { value: '1', label: 'Year 1' },
                          { value: '2', label: 'Year 2' },
                          { value: '3', label: 'Year 3' },
                          { value: '4', label: 'Year 4' },
                          { value: '5', label: 'Year 5' },
                          { value: '6', label: 'Year 6' },
                          { value: '7', label: 'Year 7' },
                          { value: '8', label: 'Year 8' },
                          { value: '9', label: 'Year 9' },
                          { value: '10', label: 'Year 10' }
                        ]}
                        required={true}
                        value={solveData.gmb_start_year}
                      />
                    </Field>
                  ) : null}
                </Row>
              )}
              {['Qualified'].includes(productData.tax_qualification) ? (
                <Checkbox children='Enforce RMDs' onChange={handleSolveChange.bind(null, 'enforce_rmds')} style={{ marginBottom: 20 }} checked={solveData.enforce_rmds} />
              ) : null}
              {['Accumulation', 'Contribution', 'Income', 'GMBIncome'].includes(solveData.solve_for) ? (
                <Row>
                  <Field label={solveData.solve_for === 'Contribution' ? 'Accumulated to the end of year' : 'Quote value at the end of year:'} required={true} style={{ flex: 1 }}>
                    <Select
                      onChange={handleSolveChange.bind(null, 'quote_value')}
                      options={[
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                        { value: '3', label: '3' },
                        { value: '4', label: '4' },
                        { value: '5', label: '5' },
                        { value: '6', label: '6' },
                        { value: '7', label: '7' },
                        { value: '8', label: '8' },
                        { value: '9', label: '9' },
                        { value: '10', label: '10' },
                        { value: '11', label: '11' },
                        { value: '12', label: '12' },
                        { value: '13', label: '13' },
                        { value: '14', label: '14' },
                        { value: '15', label: '15' },
                        { value: '16', label: '16' },
                        { value: '17', label: '17' },
                        { value: '18', label: '18' },
                        { value: '19', label: '19' },
                        { value: '20', label: '20' },
                        { value: '21', label: '21' },
                        { value: '22', label: '22' },
                        { value: '23', label: '23' },
                        { value: '24', label: '24' },
                        { value: '25', label: '25' },
                        { value: '26', label: '26' },
                        { value: '27', label: '27' },
                        { value: '28', label: '28' },
                        { value: '29', label: '29' },
                        { value: '30', label: '30' },
                        { value: '31', label: '31' },
                        { value: '32', label: '32' },
                        { value: '33', label: '33' },
                        { value: '34', label: '34' },
                        { value: '35', label: '35' },
                        { value: '36', label: '36' },
                        { value: '37', label: '37' },
                        { value: '38', label: '38' },
                        { value: '39', label: '39' },
                        { value: '40', label: '40' }
                      ]}
                      required={true}
                      value={solveData.quote_value}
                    />
                  </Field>
                </Row>
              ) : null}
            </>
          ) : null}
        </CriteriaForm>
        <CriteriaHelp $isMobile={windowWidth < 900}>
          <Heading type='tertiary'>{tab_help_content[activeTab].title}</Heading>
          <HelpContent>{tab_help_content[activeTab].description}</HelpContent>
        </CriteriaHelp>
      </CriteriaContent>
    </Modal>
  );
};

CriteriaModal.propTypes = {
  agent: PropTypes.object,
  agentErrors: PropTypes.array,
  authenticated: PropTypes.bool,
  authError: PropTypes.bool,
  carriers: PropTypes.array,
  client: PropTypes.object,
  clientErrors: PropTypes.array,
  criteriaOptions: PropTypes.object,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  product: PropTypes.object,
  productErrors: PropTypes.array,
  productsFeatures: PropTypes.array,
  setAuth: PropTypes.func,
  setTrackAgent: PropTypes.func,
  setTrackClient: PropTypes.func,
  setTrackProduct: PropTypes.func,
  setTrackSolve: PropTypes.func,
  solve: PropTypes.object,
  solveErrors: PropTypes.array,
  tab: PropTypes.string,
  validateCriteria: PropTypes.func
};

export default CriteriaModal;
